import { client } from 'src/api'

export const getFriendsAttending = async (id, page, perPage) => {
  const { data } = await client.get(`/api/v4/obe_live_classes/${id}/friends_attending`, {
    params: {
      page: page,
      per_page: perPage,
    },
  })

  return data
}

export const getUpcomingLiveClasses = async (limit, includeFriends) => {
  const {
    data: { obeClasses = [] },
  } = await client.get('/api/v4/obe_live_classes/upcoming', {
    params: {
      limit,
      include_friends_attending: includeFriends,
    },
  })

  return obeClasses
}

export const getLiveClassesByDate = async (date, includeFriends = false) => {
  const {
    data: { obeClasses = [] },
  } = await client.get('/api/v4/obe_live_classes', {
    params: {
      date,
      include_friends_attending: includeFriends,
    },
  })

  return obeClasses
}

export const getLastLiveClass = async () => await client.get('/api/v4/obe_live_classes/last')
