import { AvatarStyles, defaultStyles, FriendsTagsCompactStyles } from './styles'
import { browserSupportsWorkoutParties } from 'src/utils/browserUtils'
import { FriendsTagsCompact } from 'src/components/Friends/FriendsTagsCompact'
import { getEquipmentLabel } from 'src/utils/getEquipmentLabel'
import { LaunchPartyFlow } from 'src/views/PartyRoutes/LaunchPartyFlow'
import { Link } from 'src/components/Link'
import moment from 'moment'
import { Icon } from 'src/components/Icon'
import { Star } from 'src/components/Star'
import { TimeAndType } from './TimeAndType'
import { useLiveClassStatus } from 'src/hooks/useLiveClassStatus'
import { useResponsive } from 'src/hooks/useResponsive'
import { useUser } from 'src/hooks/useUser'
import { useTrainingJourney } from 'src/hooks/useTrainingJourney'
import { useNavigationTracker } from 'src/providers/NavigationTrackerProvider'
import { widths } from 'src/utils/image'

export const Event = ({
  className,
  event,
  isCompact = false,
  isProfileSchedule = false,
  isMySchedule = false,
  isStarred = false,
  styles = {},
  trackingContext,
  hasMultiInstructors,
  eventsList,
  onCalendarChange,
}) => {
  const {
    duration,
    equipment,
    id,
    instructors = [],
    isEncore,
    reservation,
    specialGuest,
    startTime: eventStartTime,
    theme,
    title,
    isCompleted = false,
    classLengthTitle,
    isGex,
    classFormat,
    classPartyId,
  } = event

  const startTime = moment(reservation?.startTime ?? eventStartTime)
  const endTime = moment(reservation?.startTime ?? eventStartTime).add(duration, 'seconds')
  const eventEnded = endTime.isBefore(moment())
  const partyAvailable = !eventEnded && startTime.isBefore(moment().add(48, 'hours'))
  const instructor = (instructors || [])[0]
  const instructorAvatarUrl =
    isMySchedule && !classPartyId && (reservation?.videoId || isGex)
      ? `/videos/${reservation?.videoId ?? id}`
      : `/instructors/${instructor?.slug}`

  const { isMember } = useUser()
  const { isMediumAndDown, isLargeAndUp } = useResponsive()
  const { isInProgress: eventOngoing } = useLiveClassStatus(event)
  const { setNavigatedFrom } = useNavigationTracker()

  const trainingJourney = useTrainingJourney(isGex)

  const itemTracking = {
    videoId: id,
    videoTitle: title,
    videoUrl: instructorAvatarUrl,
    duration: duration,
    itemType: 'class',
    trainingJourney,
  }

  const [showPartyModal, setShowPartyModal] = React.useState(false)
  const [hourMinute, meridiem] = startTime.format('h:mm A').split(' ')
  const Styles = { ...defaultStyles, ...styles }

  const friendsTagsCompact = (
    <FriendsTagsCompact
      reservationItem={reservation}
      instructors={instructors}
      title={title}
      classId={id}
      styles={FriendsTagsCompactStyles}
      avatarStyles={AvatarStyles}
    />
  )

  const onPartyIconClick = () => {
    obe.analytics.track('workoutPartyIconTapped', {
      pageName: '/schedule',
    })
    setShowPartyModal(!showPartyModal)
  }

  const onVideoLinkClick = () => {
    if (isMySchedule && (reservation?.videoId || isGex)) {
      setNavigatedFrom({
        fromPage: 'home',
        fromDetails: trainingJourney,
      })
    }
  }

  const partyIcon =
    browserSupportsWorkoutParties() && partyAvailable ? (
      <Styles.PartyIcon onClick={onPartyIconClick}>
        <Icon.PartyButtonBlack />
      </Styles.PartyIcon>
    ) : null

  const classTitle = (
    <Styles.Title>
      {classLengthTitle || title}
      {isStarred && <Star />}
    </Styles.Title>
  )

  return (
    <Styles.OuterContainer className={className} isProfileSchedule={isProfileSchedule}>
      <Styles.Container>
        {!isGex &&
          React.createElement(TimeAndType, {
            hourMinute,
            isCompact: isCompact || isMediumAndDown,
            isEncore,
            isLive: eventOngoing && (isCompact || isMediumAndDown),
            isLiveClass: classFormat === 'live' && (isCompact || isMediumAndDown),
            meridiem,
            isCompleted: !!(isMySchedule && isCompleted),
          })}

        {hasMultiInstructors ? (
          <Styles.AvatarContainer
            numAvatars={instructors.length}
            isProfileSchedule={isProfileSchedule}
          >
            {instructors.map((instructor, id) => (
              <Link to={instructorAvatarUrl} key={id} doNotTrack>
                <Styles.Avatars
                  src={instructor?.avatarUrl || instructor?.avatar}
                  circle
                  isProfileSchedule={isProfileSchedule}
                  numInstructors={instructors.length}
                  eventsList={true}
                  cdnWidth={widths.avatar}
                />
              </Link>
            ))}
          </Styles.AvatarContainer>
        ) : (
          <Link
            to={instructorAvatarUrl}
            doNotTrack={!isMySchedule}
            action={obe.events.my_schedule_item_pressed}
            tracking={itemTracking}
            onClick={onVideoLinkClick}
          >
            <Styles.Avatar
              src={instructor?.avatarUrl || instructor?.avatar}
              circle
              isProfileSchedule={isProfileSchedule}
              cdnWidth={widths.avatar}
            />
          </Link>
        )}

        <Styles.Details>
          <Styles.InnerDetailsOne>
            {isMySchedule && !!classPartyId && (
              <Styles.PartyTitle to={`/party-detail/${classPartyId}`}>
                Workout Party!
              </Styles.PartyTitle>
            )}
            {isMySchedule && !classPartyId ? (
              <Link
                to={instructorAvatarUrl}
                action={obe.events.my_schedule_item_pressed}
                tracking={itemTracking}
                onClick={onVideoLinkClick}
              >
                {classTitle}
              </Link>
            ) : (
              classTitle
            )}
            {instructors && (
              <Styles.Instructors>
                {instructors.map(({ id, slug, firstName, lastName, name }) => (
                  <Link to={`/instructors/${slug}`} key={id} doNotTrack>
                    {name ? `${name}` : `${firstName} ${lastName}`}
                  </Link>
                ))}
              </Styles.Instructors>
            )}
            {(specialGuest || theme) && (
              <Styles.HighlightContainer>
                {specialGuest && <Styles.Highlight>{specialGuest}</Styles.Highlight>}
                {theme && <Styles.Highlight>{theme}</Styles.Highlight>}
              </Styles.HighlightContainer>
            )}
          </Styles.InnerDetailsOne>
          <Styles.InnerDetailsTwo>
            <div>{getEquipmentLabel(equipment, 'No Equipment')}</div>
            {!isCompact && !isLargeAndUp && reservation?.friendsAlsoAttendingCount > 0 && (
              <Styles.FriendsContainer>{friendsTagsCompact}</Styles.FriendsContainer>
            )}
          </Styles.InnerDetailsTwo>
          {!isCompact &&
            isLargeAndUp &&
            (eventOngoing ? (
              <Styles.InnerDetailsThree>Live</Styles.InnerDetailsThree>
            ) : reservation?.friendsAlsoAttendingCount > 0 ? (
              <Styles.FriendsContainer>{friendsTagsCompact}</Styles.FriendsContainer>
            ) : null)}
        </Styles.Details>

        {isMember && !classPartyId && !eventOngoing && (
          <Styles.IconContainer eventsList={eventsList} isProfileSchedule={isProfileSchedule}>
            {!isMySchedule && partyIcon}
            <Styles.ToggleCalendar
              isDisabled={eventEnded}
              video={event}
              trackingContext={trackingContext}
              onCalendarChange={onCalendarChange}
            />
          </Styles.IconContainer>
        )}
        {isMember && !classPartyId && eventOngoing && (
          <Styles.IconContainer eventsList={eventsList} isProfileSchedule={isProfileSchedule}>
            {!isMySchedule && partyIcon}
            <Link to={'/'} style={{ marginLeft: '17px' }}>
              <Styles.Play />
            </Link>
          </Styles.IconContainer>
        )}
      </Styles.Container>

      <LaunchPartyFlow
        event={event}
        isOpen={showPartyModal}
        setIsOpen={setShowPartyModal}
        eventStartTime={startTime.toDate()}
        eventEndTime={endTime.toDate()}
        isLiveEvent={true}
        onScheduleParty={onCalendarChange}
      />
    </Styles.OuterContainer>
  )
}
