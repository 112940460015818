import { Avatar } from 'src/components/Display/Avatar'
import { breakpoint, copy, mixin } from 'src/styles'
import { Dialog } from 'src/components/Dialog'
import { Flex } from 'src/components/Display/Flex'
import { getFriendsAttending } from 'src/models/liveClass'
import { getMemberDisplayName } from 'src/utils/formattingUtils'
import { Loader } from 'src/components/Display/Loader'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Spinner } from 'src/components/Spinner'
import { Link } from 'src/components/Link'
import { Image } from 'src/components/Display/Image'
import moment from 'moment'
import { useAsyncCallback } from 'react-async-hook'

const PER_PAGE = 6

export const FriendsTagsModal = ({
  isOpen,
  setIsOpen,
  reservationItem = {},
  classId,
  instructors,
  title,
}) => {
  const [friendsAttending, setFriendsAttending] = React.useState([])
  const [friendsAttendingCount] = React.useState(reservationItem?.friendsAlsoAttendingCount)
  const startTime = moment(reservationItem?.reservationStartTime)
  const day = startTime.format('ddd')
  const [dateMonth, dateNumber] = startTime.format('MMM D').split(' ')
  const [hourMinute, meridiem] = startTime.format('h:mm A').split(' ')
  const [page, setPage] = React.useState(1)

  const asyncGetFriendsAttending = useAsyncCallback(async () => {
    const { friendsAlsoAttending } = await getFriendsAttending(classId, page, PER_PAGE)

    setPage(page + 1)

    setFriendsAttending(friendsAttending.concat(friendsAlsoAttending))
  })

  React.useEffect(() => {
    if (isOpen) {
      asyncGetFriendsAttending.execute()
    }
  }, [isOpen])

  return (
    <Styles.Dialog hideScrollbar isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <Styles.Dismiss
        noBorder
        onClick={() => {
          setIsOpen(false)
          setPage(1)
          setFriendsAttending([])
        }}
      />
      <Styles.ModalContainer>
        <Styles.Header>
          {friendsAttendingCount} of your obé friends are taking this class, too!
        </Styles.Header>
        <Styles.ClassCardContainer>
          <Styles.DateContainer>
            <Styles.Day>{day}</Styles.Day>
            <div>
              {dateMonth}. {dateNumber}
            </div>
          </Styles.DateContainer>
          <Styles.TimeContainer>
            <Styles.Time>
              {hourMinute}
              <Styles.Meridiem>{meridiem}</Styles.Meridiem>
            </Styles.Time>
          </Styles.TimeContainer>
          <Styles.AvatarAndDetails>
            <Styles.AvatarLink to={`/instructors/${instructors?.[0].id}`}>
              <Styles.InstructorAvatar src={instructors?.[0].avatarUrl} circle />
            </Styles.AvatarLink>
            <div>
              {title}
              <Styles.Instructors>
                {(instructors || []).map(({ id, slug, name }) => (
                  <Link to={`/instructors/${slug}`} key={id}>
                    {name}
                  </Link>
                ))}
              </Styles.Instructors>
            </div>
          </Styles.AvatarAndDetails>
        </Styles.ClassCardContainer>
        <Styles.ScrollContainer id='scrollableModal'>
          <div style={{ marginTop: '20px' }} />
          <Loader isLoading={friendsAttending?.length === 0 && asyncGetFriendsAttending?.loading}>
            <InfiniteScroll
              next={() => asyncGetFriendsAttending?.execute()}
              hasMore={friendsAttending?.length < friendsAttendingCount}
              style={{ overflow: 'visible' }}
              loader={<Spinner />}
              scrollableTarget='scrollableModal'
              dataLength={friendsAttending?.length}
            >
              <Flex.Grid mediumAndUp={{ cols: 2, gutter: 24 }}>
                {friendsAttending?.map(
                  ({
                    id,
                    friendUser: {
                      profile: { avatarUrl, firstName, lastName, username },
                      account: { annual, quarterly },
                    },
                  }) => (
                    <Styles.FriendCard key={id} to={`/${username}`}>
                      <Styles.UserAvatarContainer>
                        <Styles.UserAvatar
                          avatarUrl={avatarUrl}
                          firstName={firstName}
                          lastName={lastName}
                          isLevelUp={annual || quarterly}
                        />
                      </Styles.UserAvatarContainer>
                      <Styles.Name>
                        {getMemberDisplayName(
                          { profile: { username, firstName, lastName } },
                          { useFullNameWithInitial: true }
                        )}
                      </Styles.Name>
                    </Styles.FriendCard>
                  )
                )}
              </Flex.Grid>
            </InfiniteScroll>
          </Loader>
        </Styles.ScrollContainer>
      </Styles.ModalContainer>
    </Styles.Dialog>
  )
}

const Styles = {
  ModalContainer: styled.div`
    display: flex;
    flex-direction: column;
    min-height: 500px;
  `,
  Header: styled.div`
    ${copy.headerXlarge}
    line-height: 38px;
    padding: 0 2rem 1.5rem;
    font-weight: 200;
    text-align: center;
    color: #1a1919;

    ${breakpoint.smallAndDown} {
      padding: 0 1rem 1.5rem;
    }
  `,
  ClassCardContainer: styled.div`
    ${mixin.flexCenterBoth}
    ${mixin.spaceChildrenHorizontal('16px')}
    margin: 0 40px 8px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    font-size: 21px;
    font-weight: 300;
    line-height: 1;

    ${breakpoint.smallAndDown} {
      margin: 0 0 8px;
      padding: 16px;
      font-size: 18px;
    }
  `,
  DateContainer: styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    letter-spacing: 0.5px;
    flex-shrink: 0;
  `,
  Day: styled.div`
    position: absolute;
    top: -16px;
    font-size: 13px;
    margin-bottom: 5px;
    text-transform: uppercase;
  `,
  TimeContainer: styled.div`
    display: flex;
  `,
  Time: styled.div`
    display: flex;
    align-items: baseline;
  `,
  Meridiem: styled.div`
    font-size: 12px;
    margin-left: 4px;
  `,
  AvatarAndDetails: styled.div`
    ${mixin.flexCenterVertical}
  `,
  AvatarLink: styled(Link)`
    margin: -5px 12px 0 0;
  `,
  InstructorAvatar: styled(Image)`
    flex-grow: 0;
    flex-shrink: 0;
    width: 61px;

    ${breakpoint.smallAndDown} {
      width: 50px;
    }
  `,
  Instructors: styled.div`
    ${mixin.spaceChildrenVertical('.3rem')}
    display: flex;
    flex-direction: column;
    margin-top: 0.6rem;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
  `,
  ScrollContainer: styled.div`
    overflow-y: auto;
    height: 270px;

    > div:nth-of-type(2) {
      ${breakpoint.smallAndDown} {
        padding: 0 16px;
        margin: 0 !important;
      }

      ${breakpoint.mediumAndUp} {
        width: calc(100% - 80px);
        margin: 0 auto !important;
      }
    }
  `,
  FriendCard: styled(Link)`
    ${mixin.flexCenterVertical}
    justify-content: flex-start;

    :hover {
      text-decoration: none;
    }
  `,
  UserAvatarContainer: styled.div`
    ${mixin.flexCenterVertical}
    margin-right: 16px;

    > div {
      height: 60px;
      width: 60px;
    }
  `,
  UserAvatar: styled(Avatar)`
    font-size: 24px;
  `,
  Name: styled.div`
    font-size: 18px;
    font-weight: 300;
  `,
  Dismiss: styled(Dialog.Header)`
    padding: 0 0 5px 0;
  `,
  Dialog: styled(Dialog.Main)`
    left: 0px;
    top: 0px;
    max-width: 500px;
    width: 100%;
    height: 100%;
    padding: 20px !important;

    background: #ffffff;
    position: relative;
    overflow-x: hidden;
  `,
  DialogBody: styled.div`
    padding: 16px;
  `,
}
