import { mixin } from 'src/styles'
import { Icon } from 'src/components/Icon'

export const TimeAndType = ({
  hourMinute,
  isCompact,
  isEncore,
  isLive,
  isLiveClass,
  meridiem,
  isCompleted = false,
}) => (
  <Styles.Container isCompact={isCompact}>
    {isCompleted && (
      <Styles.Completed>
        <Icon.CheckmarkCircle />
      </Styles.Completed>
    )}
    {hourMinute} <Styles.Meridiem>{meridiem}</Styles.Meridiem>
    {((isLiveClass && !isEncore) || isLive) && (
      <Styles.LiveText isOngoing={isLive}>Live</Styles.LiveText>
    )}
    {isEncore && !isLive && <Styles.EventType isCompact>Encore</Styles.EventType>}
  </Styles.Container>
)

const Styles = {
  Container: styled.div`
    ${mixin.flexStatic}
    font-size: 23px;
    line-height: 18px;
    width: 85px;

    ${({ isCompact }) =>
      isCompact &&
      css`
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 18px;
        margin-top: 5px;
        width: 70px;
      `}
  `,
  Meridiem: styled.span`
    font-size: 12px;
  `,
  Duration: styled.div`
    color: var(--mediumGrey);
    font-size: 14px;
    margin-top: 4px;
  `,
  LiveText: styled.div`
    color: ${({ isOngoing }) => (isOngoing ? 'red' : 'var(--neutrals700)')};
    font-weight: 200;
    font-size: 14px;
    letter-spacing: 0.015em;
    margin-top: 6px;
    text-transform: uppercase;
  `,
  EventType: styled.div`
    color: var(--persianBlue);
    font-size: 11px;
    letter-spacing: 0.85px;
    margin-top: 0.6rem;
    text-transform: uppercase;

    ${({ isCompact }) =>
      isCompact &&
      css`
        margin-top: 0.3rem;
        color: var(--neutrals700);
        font-weight: 200;
        font-size: 14px;
      `}
  `,
  Completed: styled.div`
    margin-bottom: 6px;
  `,
}
