import { Avatar } from 'src/components/Display/Avatar'
import { UserAction } from 'src/components/UserAction'
import { FriendsTagsModal } from './FriendsTagsModal'
import { getMemberDisplayName } from 'src/utils/formattingUtils'
import { mixin } from 'src/styles'

export const FriendsTagsCompact = ({
  reservationItem,
  instructors,
  title,
  classId,
  styles,
  avatarStyles,
  ...options
}) => {
  const [showModal, setShowModal] = React.useState(false)

  const { friendsAlsoAttending, friendsAlsoAttendingCount } = reservationItem
  const Styles = { ...DefaultStyles, ...styles }
  const usersNames =
    friendsAlsoAttending?.map((friend) =>
      getMemberDisplayName(friend?.friendUser, { useFirstName: true })
    ) || []

  const containerContent = (
    <React.Fragment>
      <Styles.UserAvatars>
        {friendsAlsoAttending?.map((friend) => (
          <Avatar
            key={friend?.id}
            avatarUrl={friend?.friendUser?.profile?.avatarUrl}
            firstName={friend?.friendUser?.profile?.firstName}
            lastName={friend?.friendUser?.profile?.lastName}
            isMiniAvatar={true}
            styles={avatarStyles}
          />
        ))}
      </Styles.UserAvatars>
      <Styles.Names>
        {usersNames.join(', ')}
        {friendsAlsoAttendingCount > 3 && ` +${friendsAlsoAttendingCount - 3}`}
      </Styles.Names>
    </React.Fragment>
  )

  const modal = (
    <FriendsTagsModal
      reservationItem={reservationItem}
      instructors={instructors}
      title={title}
      classId={classId}
      isOpen={showModal}
      setIsOpen={setShowModal}
    />
  )

  return friendsAlsoAttendingCount > 3 ? (
    <Styles.LinkContainer
      tracking={{ action: 'my_obe_schedule_friends_see_all' }}
      onClick={() => setShowModal(true)}
      {...options}
    >
      {containerContent}
      {modal}
    </Styles.LinkContainer>
  ) : (
    <Styles.Container {...options}>
      {containerContent}
      {modal}
    </Styles.Container>
  )
}

const DefaultStyles = {
  LinkContainer: styled(UserAction)`
    ${mixin.flexCenterVertical}
    margin-top: 1rem;
  `,
  Container: styled.div`
    ${mixin.flexCenterVertical}
    margin-top: 1rem;
  `,
  UserAvatars: styled.div`
    display: flex;
    width: fit-content;

    > div:not(:first-of-type) {
      margin-left: -6px;
    }
  `,
  Names: styled.div`
    font-size: 13px;
    letter-spacing: 0.2px;
    margin-left: 8px;
    line-height: 16px;
  `,
}
